@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: saira-regular;
  src: url(../public/fonts/saira_condensed/SairaCondensed-Regular.ttf);
}

@font-face {
  font-family: saira-bold;
  src: url(../public/fonts/saira_condensed/SairaCondensed-Bold.ttf);
}

@font-face {
  font-family: inter-regular;
  src: url(../public/fonts/inter/static/Inter-Regular.ttf);
}

* {
  border: 0px solid black;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 25px;
}

h1 {
  font-family: saira-bold;
}

p {
  font-family: saira-regular;
}

a {
  color: #FFF;
  text-decoration: none;
}

body {
  display: flex;
  justify-content: center;
  background: #545454;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1080px;
  background: var(--Soft-Grey, #E8E8E8);
}

.sign-up {
  font-family: saira-bold;
  width: 106px;
  font-size: 16px;
  height: 36px;
  padding: 10px 30px;
  line-height: 100%;
  text-transform: uppercase;
  border: 3px solid #F46E6E;
  transition: background 0.3s ease;
  background: #F46E6E;

  &:hover {
    background: #F46E6E00;
  }
}

.hero {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 800px;

  .navbar {
    // background: rgba(0, 0, 0, 0.25);
    display: flex;
    z-index: 10;
    justify-content: space-between;
    height: 56px;
    width: 80%;
    // flex-grow: 1;
    // gap: auto;
    box-sizing: border-box;
    margin-top: 90px;
    margin-left: 10%;
    margin-right: 10%;

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 206px;

      &__site-name {
        color: #FFF;
        font-family: saira-bold;
        font-size: 32px;
        font-style: normal;
        margin-left: 8px;
        font-weight: 700;
        line-height: 90%; /* 28.8px */
        text-transform: uppercase;
        transition: color 0.3s ease;

        &:hover {
          color: #F46E6E;
        }
      }
    }

    &__menu {
      display: none;
    }

    &__links {
      display: flex;
      align-items: center;
      height: 100%;
      width: 414px;
  
      ul {
        display: flex;
        flex-grow: 1;
        align-items: center;
        list-style: none;
      }
    
      li {
        margin-left: auto;
        padding: 0;
        font-family: saira-bold;
        font-size: 16px;
        line-height: 100%; /* 16px */
        text-transform: uppercase;
    
        & a:not(.sign-up) {
          transition: all 0.3s ease;
        }
    
        & a:hover:not(.sign-up) {
          color: #F46E6E; 
        }
    
        &:first-child {
          margin: 0;
        }
    
        &__sign-up {
          margin-left: auto;
        }
      }
    }
  } // navbar

  &__img {
    position: absolute;
    top: -10px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }

  h1 {
    color: #FFF;
    position: absolute;
    top: 50vh;
    left: 365px;
    transform: translate(-50%, -50%);
    font-size: 4rem; // orig 100px
    z-index: 2;
    line-height: 90%;
    text-transform: uppercase;

    span {
      color: #F46E6E;
    }
  }
  
} // hero

.services-box {
  background: #FFF;
  display: flex;
  position: absolute;
  z-index: 3; 
  bottom: 40px;
  justify-content: space-evenly;
  align-items: center;
  width: 645px;
  height: 160px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    img {
      width: 40px;
      height: 40px
    }

    p {
      font-size: 24px;
      line-height: 110%;
      font-family: saira-bold;
      margin-top: 10px;
      color: #F46E6E;
      text-transform: uppercase;
    }
  }
} // services box

.content-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  // flex-basis: 1;
  height: auto; // orig 435px
  padding: 0 75px; // left-right: 75px
  margin: 50px 0 100px 0;

  #second-heading {
    display: none;
  }

  h1 {
    border-left: 6px solid #F46E6E;
    padding-left: 16px;
    border-bottom: 0;
    color: var(--Black, #000);
    font-family: saira-bold;
    font-size: 50px;
    line-height: 100%; /* 50px */
    text-transform: uppercase;
  }

  &__lower {
    display: flex;
    gap: 55px;
    width: 100%;
    // flex-wrap: wrap;
    
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;
      width: 100%;
      gap: 30px;
      // flex-grow: 1;

      p {
        color: var(--Grey, #444343);
        font-family: inter-regular;
        font-size: 16px;
        line-height: 170%; /* 27.2px */
        font-style:  normal;
        width: 100%;
      }

      &__sign-up {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        line-height: 100%;
        height: 60px; // orig 50px
        width: 200px; // orig 120px
      }
    }

    img {
        width: 440px; // orig 440px
        // height: 320px; // orig 320px
        object-fit: cover;
        object-position: center;
    }
  }

} // content item

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--Black, #000);
  height: 500px;
  gap: auto;
  width: 100%;
  padding: 75px 120px; // orig top-bottom 95px

  &__social-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: auto;
    height: 34px;
    width: 367px;

    img {
      object-fit: cover;
      height: 40px;
      width: 40px;
    }
  }

    &__privacy-tc {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      width: 300px;
      height: 27px;
      white-space: nowrap;

      span {
        color: #FFF;
        font-family: inter-regular;
        font-size: 16px;
        font-style: normal;
      }
    }

    &__email {
      display: flex;
      height: 22.5px;
      width: 213px;
      align-items: center;
      justify-content: space-between;

      img {
        object-fit: cover;
        height: 22.5px;
        width: 30px;
      }

      p {
        color: #FFF;
        font-family: inter-regular;
        font-size: 16px;
        font-style: normal;
        line-height: 170%;
      }
    }
    
    h1 {
      color: var(--White, #FFF);
      font-family: saira-bold;
      font-size: 50px;
      font-style: normal;
      line-height: 100%;
      text-transform: uppercase;
        
      span {
        color: #F46E6E;
      }
    }
  } // footer

/*
  Media queries
*/

@media (width < 1080px) {
  .main-container { 
    width: 100vw;
  }

  .hero {
    .navbar {
      &__links {
        display: none;
      }
  
      &__menu {
        display: flex;
        height: 100%;
        margin-left: auto;
        
        img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
  
    } // navbar
  }

  .content-item {
    flex-grow: 1;

    #first-heading {
      display: none;
    }

    #second-heading {
      display: flex;
    }

    &__lower {
      flex-direction: column;
      align-items: center;
      gap: 30px;

      &__text {
        align-items: center;
      }

      img {
        width: 100%;
      }
    }
  }
}


@media (width < 820px) {

  .hero {
    h1 {
      font-size: 3rem;
      left: 250px;
    }
  }

  .services-box { 
    width: 500px;
    height: 140px;

    img {
      height: 30px;
      width: 30px;
    }

    p {
      font-size: 22px;
    }
  }
}

// @media (width < 600px) {
//   .services-box {
//     flex-direction: column;
//     height: auto;
//     width: 8rem;
//   }
// }